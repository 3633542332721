import { render, staticRenderFns } from "./DispatchLocationView.vue?vue&type=template&id=ec85c3ee"
import script from "./DispatchLocationView.vue?vue&type=script&lang=js"
export * from "./DispatchLocationView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports